<template>
  <section class="register-company-section">
    <Alert
      :is-error-alert-open="isErrorAlertOpen"
      :error-alert-message="errorAlertMessage"
      @close="closeErrorAlert"
    />
    <div class="register-company-section__breadcrumb">
      <BreadCrumb :is-use-route-name="true" />
    </div>
    <div
      v-if="decideShowBanner"
      class="register-company-section__banner"
    >
      <img
        src="/images/agent/register-individual-banner-mobile.svg"
        alt="Register Banner"
      >
    </div>
    <div class="register-company-section__container">
      <div class="register-company-section__container__stepper">
        <div class="register-company-section__container__stepper__step">
          <span class="register-company-section__container__stepper__step__count active">
            1
          </span>
          <div class="register-company-section__container__stepper__step__label">
            Form Pendaftaran
          </div>
        </div>
        <img
          class="register-company-section__container__stepper__divider"
          src="/images/arrow-stepper.svg"
          alt="arrow stepper"
        >
        <div class="register-company-section__container__stepper__step">
          <span class="register-company-section__container__stepper__step__count">
            2
          </span>
          <div class="register-company-section__container__stepper__step__label">
            Dokumen Pendukung
          </div>
        </div>
      </div>
      <form
        class="register-company-section__container__form"
      >
        <div
          v-for="section of forms"
          :key="section.section"
        >
          <h1 :id="section.id">
            {{ section.section }}
          </h1>
          <component
            :is="decideComponentType(form)"
            v-for="form of section.forms"
            :key="form.value_key"
            v-model="formsValue[form.value_key]"
            :name="form.name"
            :placeholder="decidePlaceholder(
              form.placeholder,
              form.placeholder_mobile,
              form.type,
              formsValue[form.value_key],
              form.value_key
            )"
            :prefix="form.prefix"
            :options="form.options"
            :is-search="form.isSearch"
            :label="form.label"
            :is-required="form.required"
            :is-optional="form.optional"
            :icon-type="form.iconType"
            :note="form.note"
            :value="formsValue[form.value_key]"
            :is-error="
              checkIsFormError(form.value_key, form.required, form.type,)
            "
            :error-message="
              formErrorMessages[form.value_key] || form.errorMessage
            "
            :disabled="decideDisableForm(form.type, form.options, form.value_key)"
            captcha-name="agent-campany"
            :generate-captcha-func="generateCaptcha"
            :validate-captcha-func="validateCaptcha"
            :is-captcha-expired="isCaptchaExpired"
            @open="onOpenCaptcha"
            @input="(val) => onInput(val, form.value_key)"
            @verified="onCaptchaVerified"
            @max-attempted="onCaptchaMaxAttempted"
            @failed="onCaptchaFailed"
          />
        </div>
        <div class="register-company-section__container__form__note">
          Dengan menyelesaikan pendaftaran maka saya setuju untuk mengikuti syarat dan ketentuan yang berlaku sesuai dengan
          <a
            href="https://storage.googleapis.com/internal-website/agent/Syarat%20&%20Ketentuan%20Agen%20Lion%20Parcel.pdf"
            target="_blank"
          >
            Dokumen ini
          </a>
        </div>
        <div
          id="submit"
          class="register-company-section__container__form__submit"
        >
          <Button
            text="Kirim"
            @click="submit"
          />
        </div>
      </form>
    </div>
  </section>
</template>
<script>
import { mapActions } from 'vuex';
import mixinMetaInfo from '@/misc/mixinMetaInfo';
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb.vue';
import Input from '../../components/InputField.vue';
import Dropdown from '../../components/DropDown.vue';
import Button from '@/components/new-button/Button.vue';
import Forms from '../../misc/companyForms';
import mixinMobile from '@/misc/mixinMobile';
import AgentUseCase from '../../app/usecase/agentRegister';
import Captcha from '@/components/captcha-slider/Captcha.vue';
import CaptchaUseCase from '@/pages/new-agent/app/usecase/captcha';
import Alert from '../../components/Alert.vue';
import mixinAlert from '../../mixins/mixinAlert';
import Cookies from "js-cookie";

export default {
  name: 'RegisterIndividual',
  components: {
    BreadCrumb, Input, Dropdown, Button, Captcha, Alert,
  },
  mixins: [mixinMobile, mixinMetaInfo, mixinAlert],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      searchMetaInfo: '_agen_perusahaan-app',
      value: null,
      forms: Forms,
      formsValue: {
        registration_type: 'Badan Usaha',
        captcha: false,
        company_type_text: '',
        other_business: '',
        axis: null,
      },
      showErrorField: false,
      formErrorMessages: {},
      utmParams: {
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: '',
        fbc: '',
        fbp: '',
        gclid: '',
      },
      isCaptchaExpired: false,
    };
  },
  computed: {
    decideShowBanner() {
      return this.windowWidth <= 600;
    },
  },
  mounted() {
    this._companyTypeNoValCondition();
    this._otherBussinessNoValCondition();
    this.setProvinceListOptions();
    if (this.$route.query.referral) {
      this.setReferralValue();
    }
    this.setUTMValues();
    window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
  },
  destroyed() {
    Cookies.remove('_fbc', { path: '/', domain: this.getDomain() })
  },
  methods: {
    ...mapActions(['setAgentId']),
    decideDisableForm(type, options, key) {
      if (this.$route.query.referral && (key === 'referral_code' || key === 'resource')) {
        return true;
      }
      return type === 'dropdown' && !options?.length;
    },
    setReferralValue() {
      this.formsValue.resource = [{
        label: 'Rekomendasi dari Agen POS lain',
        value: 'Rekomendasi dari Agen POS lain',
      }];
      this.formsValue.referral_code = this.$route.query.referral;
    },
    parseFormValue() {
      const tempFormsValue = { ...this.formsValue };
      Object.keys(tempFormsValue).forEach((key) => {
        if (Array.isArray(this.formsValue[key])) {
          tempFormsValue[key] = this.formsValue[key][0].value;
        }
        if (key === "is_have_other_business") {
          tempFormsValue["is_have_other_business"] !== "Tidak ada" ? 
          tempFormsValue["is_have_other_business"] = true : tempFormsValue["is_have_other_business"] = false
        }
        if (key === 'other_business') {
          tempFormsValue.building_status += ' (Usaha Lain)';
        }
        if (key === 'company_type_text' && this.formsValue[key]) {
          tempFormsValue.company_type = this.formsValue[key];
        }
      });
      return tempFormsValue;
    },
    async setProvinceListOptions() {
      try {
        const res = await AgentUseCase.getProvince();
        if (res.data) {
          this.forms[2].forms.find(form => form.value_key === 'province').options = res.data.map(prov => ({
            ...prov,
            label: prov.province_name,
            value: prov.province_name,
          }));
        }
      } catch (error) {
        return error;
      }
    },
    setLocationOptions(key, value) {
      this.forms[2].forms.find(form => form.value_key === key).options = value;
    },
    resetLocationOptions(key) {
      this.forms[2].forms.find(form => form.value_key === key).options = [];
      this.formsValue[key] = [];
    },
    async getDistrictListOptions(province_id) {
      this.resetLocationOptions('district');
      this.resetLocationOptions('sub_district');
      this.resetLocationOptions('village');
      try {
        const res = await AgentUseCase.getDistrict(province_id);
        if (res.data) {
          const district = res.data.map(dist => ({
            ...dist,
            label: dist.district_name,
            value: dist.district_name,
          }));
          this.setLocationOptions('district', district);
        }
      } catch (error) {
        return error;
      }
    },
    async getSubDistrictListOptions(district_id) {
      this.resetLocationOptions('sub_district');
      this.resetLocationOptions('village');
      try {
        const res = await AgentUseCase.getSubDistrict(district_id);
        if (res.data) {
          const subDistrict = res.data.map(subDist => ({
            ...subDist,
            label: subDist.sub_district_name,
            value: subDist.sub_district_name,
          }));
          this.setLocationOptions('sub_district', subDistrict);
        }
      } catch (error) {
        return error;
      }
    },
    async getVillageListOptions(sub_district_id, reset = true) {
      if (reset) {
        this.resetLocationOptions('village');
      }
      try {
        const res = await AgentUseCase.getVillage(sub_district_id);
        if (res.data) {
          const village = res.data.map(village => ({
            ...village,
            label: village.village_name,
            value: village.village_name,
          }));
          this.setLocationOptions('village', village);
        }
      } catch (error) {
        return error;
      }
    },
    _phoneNumberCondition(key, val) {
      if (val.split('').length > 15) {
        const tempVal = val.split('').slice(0, -1).join('');
        this.formsValue[key] = tempVal;
        document.querySelector(`input[name="${key}"]`).value = tempVal;
      }
      if (this.formsValue[key].split('')[0] !== '8') {
        this.formsValue[key] = '';
        document.querySelector(`input[name="${key}"]`).value = '';
      }
    },
    _otherBussinessCondition(val) {
       if (val[0].label === "Lainnya") {
        this.formsValue["other_business"] = "";
        this.forms[2].forms.splice(4, 0, {
          name: 'other_business',
          label: '',
          type: 'text',
          placeholder: 'Masukkan usaha lain',
          errorMessage: 'Usaha Lainnya harus diisi',
          required: true,
          value_key: 'other_business',
        });
      } 
      else if (val[0].label !== "Lainnya" && val[0].label !== "Tidak ada") {
        this.formsValue["other_business"] = val[0].label;
        if (this.forms[1].forms[4]?.name === "other_business") {
          this.forms[1].forms.splice(4, 1);
        }
      }
      else if (this.forms[2].forms[4]?.name === 'other_business') {
        this.formsValue.other_business = '';
        this.forms[2].forms.splice(4, 1);
      }
    },
    _otherBussinessNoValCondition() {
      if (this.forms[2].forms[4]?.name === 'other_business') {
        this.formsValue.other_business = '';
        this.forms[2].forms.splice(4, 1);
      }
    },
    _companyTypeCondition(val) {
      if (val[0].value === 'Lainnya') {
        this.forms[1].forms.splice(1, 0, {
          name: 'company_type_text',
          label: '',
          type: 'text',
          placeholder: 'Lainnya',
          errorMessage: 'Lainnya harus diisi',
          required: true,
          value_key: 'company_type_text',
        });
      } else if (this.forms[1].forms[1]?.name === 'company_type_text') {
        this.formsValue.company_type_text = '';
        this.forms[1].forms.splice(1, 1);
      }
    },
    phoneNumber(key, val) {
      if (key === 'phone_number' || key === 'phone_number_two') {
        this._phoneNumberCondition(key, val);
      }
    },
    provinceExists(key, val) {
      if (key === 'province' && val.length) {
        this.getDistrictListOptions(val[0].province_id);
      }
    },
    provinceNotExists(key, val) {
      if (key === 'province' && !val.length) {
        this.resetLocationOptions('district');
        this.resetLocationOptions('sub_district');
        this.resetLocationOptions('village');
      }
    },
    districtExists(key, val) {
      if (key === 'district' && val.length) {
        this.getSubDistrictListOptions(val[0].district_id);
      }
    },
    districtNotExists(key, val) {
      if (key === 'district' && !val.length) {
        this.resetLocationOptions('sub_district');
        this.resetLocationOptions('village');
      }
    },
    subDistrictExists(key, val) {
      if (key === 'sub_district' && val.length) {
        this.getVillageListOptions(val[0].sub_district_id);
      }
    },
    subDistrictNotExists(key, val) {
      if (key === 'sub_district' && !val.length) {
        this.resetLocationOptions('village');
      }
    },
    vilageExists(key, val) {
      if (key === 'village' && val.length) {
        this.getVillageListOptions(this.formsValue.sub_district[0].sub_district_id, false);
      }
    },
    vilageNotExists(key, val) {
      if (key === 'village' && !val.length) {
        this.getVillageListOptions(this.formsValue.sub_district[0].sub_district_id);
      }
    },
    otherBussinesExists(key, val) {
      if (key === 'is_have_other_business' && val.length) {
        this._otherBussinessCondition(val);
      }
    },
    otherBussinesNotExists(key, val) {
      if (key === 'is_have_other_business' && !val.length) {
        this._otherBussinessNoValCondition();
      }
    },
    companyTypeExists(key, val) {
      if (key === 'company_type' && val.length) {
        this._companyTypeCondition(val);
      }
    },
    companyTypeNotExists(key, val) {
      if (key === 'company_type' && !val.length) {
        this.formsValue.company_type_text = '';
        this.forms[1].forms.splice(1, 1);
      }
    },
    onInput(val, key) {
      this.phoneNumber(key, val);
      this.provinceExists(key, val);
      this.provinceNotExists(key, val);
      this.districtExists(key, val);
      this.districtNotExists(key, val);
      this.subDistrictExists(key, val);
      this.subDistrictNotExists(key, val);
      this.vilageExists(key, val);
      this.vilageNotExists(key, val);
      this.otherBussinesExists(key, val);
      this.otherBussinesNotExists(key, val);
      this.companyTypeExists(key, val);
      this.companyTypeNotExists(key, val);
    },
    checkIsFormError(key, required, type) {
      if (this.showErrorField && required) {
        if (!this.formsValue[key] && (key === 'register_email' || key === 'phone_number')) {
          this.formErrorMessages[key] = null;
          return true;
        } if (key === 'phone_number' && this.formsValue[key]?.length < 9) {
          this.formErrorMessages[key] = 'No. Telepon minimal 9 angka';
          return true;
        } if (
          key === 'register_email'
          && !this.isValidEmail(this.formsValue[key])
        ) {
          this.formErrorMessages[key] = 'Email tidak valid';
          return true;
        } if (type === 'dropdown') {
          return !this.formsValue[key]?.length;
        }
      }
      return this.showErrorField && required && !this.formsValue[key];
    },
    validateForm() {
      let requiredValue = this.forms.map(section => section.forms
        .filter(form => form.required)
        .map((key) => {
          if (
            key.value_key === 'phone_number'
                && this.formsValue[key.value_key]
                && this.formsValue[key.value_key].length < 9
          ) {
            return false;
          }
          if (
            key.value_key === 'phone_number_two'
              && this.formsValue[key.value_key]
              && this.formsValue[key.value_key].length < 9
          ) {
            return false;
          }
          if (
            key.value_key === 'register_email'
              && !this.isValidEmail(this.formsValue[key.value_key])
          ) {
            return false;
          }
          if (key.type === 'dropdown') {
            if (!this.formsValue[key.value_key]?.length) {
              return false;
            }
          }
          return !!this.formsValue[key.value_key];
        }));
      requiredValue = [...requiredValue[0], ...requiredValue[1], ...requiredValue[2]];

      return requiredValue.includes(false);
    },
    isValidEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    _companyTypeNoValCondition() {
      if (this.forms[1].forms[1]?.name === 'company_type_text') {
        this.formsValue.company_type_text = '';
        this.forms[1].forms.splice(1, 1);
      }
    },
    async submit() {
      this.showErrorField = true;
      if (this.validateForm()) {
        return;
      }
      try {
        const registerRes = await AgentUseCase.registerAgent({
          payload: this.parseFormValue(),
          params: this.utmParams,
        });
        if (registerRes.data && registerRes.data?.agent_id) {
          Cookies.remove('_fbc', { path: '/', domain: this.getDomain() })
          this.$router.push(`/agen/registration-success/result?q=${registerRes.data?.agent_id}`)
          this.$store.dispatch('setAgentId', registerRes.data?.agent_id);
        }
        if (registerRes.err) {
          if (registerRes.err.toLowerCase().match('captcha')) {
            this.isCaptchaExpired = true;
          }
          this.openErrorAlert(registerRes.err);
        }
      } catch (error) {
        return error;
      }
    },
    decidePlaceholder(placeholder, placeholder_mobile, type, value, key) {
      if (value) {
        if (type === 'dropdown' && value.length) {
          return value[0].label;
        }
        if (!value.length) {
          if (key === 'latlong') {
            return this.decideLatLongPlaceholder(placeholder, placeholder_mobile);
          }
          return placeholder;
        }
        return value;
      }
      if (key === 'latlong') {
        return this.decideLatLongPlaceholder(placeholder, placeholder_mobile);
      }
      return placeholder;
    },
    decideLatLongPlaceholder(placeholder, placeholder_mobile) {
      if (this.windowWidth <= 600) {
        return placeholder_mobile;
      }
      return placeholder;
    },
    decideComponentType(form) {
      if (form.type === 'dropdown') {
        return 'Dropdown';
      }
      if (form.type === 'captcha') {
        return 'Captcha';
      }
      return 'Input';
    },
    onCaptchaVerified(axis) {
      this.formsValue.captcha = true;
      this.formsValue.axis = axis;
    },
    onCaptchaMaxAttempted() {
      this.formsValue.captcha = false;
    },
    onCaptchaFailed() {
      this.formsValue.captcha = false;
    },
    setUTMValues() {
      const { utm_source, utm_medium, utm_campaign, utm_term, utm_content, fbclid, gclid } = this.$route.query;
      this.utmParams = {
        utm_source: utm_source || '',
        utm_medium: utm_medium || '',
        utm_campaign: utm_campaign || '',
        utm_term: utm_term || '',
        utm_content: utm_content || '',
        fbc: fbclid ? Cookies.get('_fbc') : '',
        fbp: Cookies.get('_fbp') || '',
        gclid: gclid ? Cookies.get('_gcl_aw') || '' : '',
      };
    },
    async generateCaptcha() {
      return await CaptchaUseCase.generateCaptcha();
    },
    async validateCaptcha(axis) {
      return await CaptchaUseCase.validateCaptcha(axis);
    },
    onOpenCaptcha() {
      this.isCaptchaExpired = false;
    },
    getDomain() {
      const url = window.location.hostname
      if (url.includes('localhost')) {
        return 'localhost'
      }
      else {
        const parts = url.split('.');
        const domain = '.' + parts.slice(-2).join('.');
        return domain
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
