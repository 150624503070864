var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"register-company-section"},[_c('Alert',{attrs:{"is-error-alert-open":_vm.isErrorAlertOpen,"error-alert-message":_vm.errorAlertMessage},on:{"close":_vm.closeErrorAlert}}),_vm._v(" "),_c('div',{staticClass:"register-company-section__breadcrumb"},[_c('BreadCrumb',{attrs:{"is-use-route-name":true}})],1),_vm._v(" "),(_vm.decideShowBanner)?_c('div',{staticClass:"register-company-section__banner"},[_c('img',{attrs:{"src":"/images/agent/register-individual-banner-mobile.svg","alt":"Register Banner"}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"register-company-section__container"},[_vm._m(0),_vm._v(" "),_c('form',{staticClass:"register-company-section__container__form"},[_vm._l((_vm.forms),function(section){return _c('div',{key:section.section},[_c('h1',{attrs:{"id":section.id}},[_vm._v("\n          "+_vm._s(section.section)+"\n        ")]),_vm._v(" "),_vm._l((section.forms),function(form){return _c(_vm.decideComponentType(form),{key:form.value_key,tag:"component",attrs:{"name":form.name,"placeholder":_vm.decidePlaceholder(
            form.placeholder,
            form.placeholder_mobile,
            form.type,
            _vm.formsValue[form.value_key],
            form.value_key
          ),"prefix":form.prefix,"options":form.options,"is-search":form.isSearch,"label":form.label,"is-required":form.required,"is-optional":form.optional,"icon-type":form.iconType,"note":form.note,"value":_vm.formsValue[form.value_key],"is-error":_vm.checkIsFormError(form.value_key, form.required, form.type,),"error-message":_vm.formErrorMessages[form.value_key] || form.errorMessage,"disabled":_vm.decideDisableForm(form.type, form.options, form.value_key),"captcha-name":"agent-campany","generate-captcha-func":_vm.generateCaptcha,"validate-captcha-func":_vm.validateCaptcha,"is-captcha-expired":_vm.isCaptchaExpired},on:{"open":_vm.onOpenCaptcha,"input":(val) => _vm.onInput(val, form.value_key),"verified":_vm.onCaptchaVerified,"max-attempted":_vm.onCaptchaMaxAttempted,"failed":_vm.onCaptchaFailed},model:{value:(_vm.formsValue[form.value_key]),callback:function ($$v) {_vm.$set(_vm.formsValue, form.value_key, $$v)},expression:"formsValue[form.value_key]"}})})],2)}),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"register-company-section__container__form__submit",attrs:{"id":"submit"}},[_c('Button',{attrs:{"text":"Kirim"},on:{"click":_vm.submit}})],1)],2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-company-section__container__stepper"},[_c('div',{staticClass:"register-company-section__container__stepper__step"},[_c('span',{staticClass:"register-company-section__container__stepper__step__count active"},[_vm._v("\n          1\n        ")]),_vm._v(" "),_c('div',{staticClass:"register-company-section__container__stepper__step__label"},[_vm._v("\n          Form Pendaftaran\n        ")])]),_vm._v(" "),_c('img',{staticClass:"register-company-section__container__stepper__divider",attrs:{"src":"/images/arrow-stepper.svg","alt":"arrow stepper"}}),_vm._v(" "),_c('div',{staticClass:"register-company-section__container__stepper__step"},[_c('span',{staticClass:"register-company-section__container__stepper__step__count"},[_vm._v("\n          2\n        ")]),_vm._v(" "),_c('div',{staticClass:"register-company-section__container__stepper__step__label"},[_vm._v("\n          Dokumen Pendukung\n        ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-company-section__container__form__note"},[_vm._v("\n        Dengan menyelesaikan pendaftaran maka saya setuju untuk mengikuti syarat dan ketentuan yang berlaku sesuai dengan\n        "),_c('a',{attrs:{"href":"https://storage.googleapis.com/internal-website/agent/Syarat%20&%20Ketentuan%20Agen%20Lion%20Parcel.pdf","target":"_blank"}},[_vm._v("\n          Dokumen ini\n        ")])])
}]

export { render, staticRenderFns }